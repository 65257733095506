@import "vt-themes.css";

#app-sidebar{
    width: var(--sidebar-width) !important;
    background-color:var(--sidebar-and-header-bg) !important;
    border-top:1px solid #000;
}

#app-sidebar .menu-item-text,#app-sidebar .menu .icon,#app-sidebar .menu-item .dropdown .text,#app-sidebar .menu-item i.mini,.active.menu-item .menu-item-icon{
   font-size:1rem !important;
   font-weight: 300;
  
}

.active.menu-item .menu-item-icon,.active.menu-item .menu-item-text{
     color:#000000;
     font-weight: bold !important;
}

/*#app-sidebar .menu-item.active .dropdown .text,#app-sidebar .active .menu-item-text{*/
/*    font-weight: bold*/
    
/*}*/

/*menu-item-icon*/

#app-sidebar .ui.menu .ui.dropdown .menu>.item{
    color: rgba(0,0,0,.87)!important
}

.ui.visible.thin.left.sidebar~.fixed, .ui.visible.thin.left.sidebar~.pusher{
    transform: translate3d(var(--sidebar-width),0,0);
}

#app-sidebar .menu-item .dropdown .text{
    padding:0px;
}

#app-sidebar .ui.inverted.menu .item:before, .ui.vertical.inverted.menu .item:before{
    background:none;
}

#app-sidebar .menu-item div{
    padding: 15px;
}


